<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Редактирование категории</div>
                <div class="page__desc">Измените данные категории</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="category !== null" class="form-group" method="POST" action="" @submit.prevent="update()">

                <label for="title">Название</label>
                <div class="control-group">
                    <input
                            v-model="category.title"
                            id="title"
                            type="text"
                            name="title"
                            class="form-control"
                            required>
                </div>
                <br>

                <label for="category_id">Категория</label>
                <div class="control-group">
                    <select
                            v-model="category.parent_id"
                            id="category_id"
                            name="category_id"
                            class="form-control"
                            required>
                        <option
                                v-bind:key="null"
                                value="null">-</option>
                        <option
                                v-for="c in categories"
                                v-bind:key="c.id"
                                :value="c.id"
                                :selected="category.parent_id === c.id">{{ c.title }}</option>
                    </select>
                </div>
                <br>

                <label for="photo">Фото</label><br>
                <img v-if="category.photo !== null" class="img-thumbnail" :src="category.photo">
                <div class="control-group">
                    <input ref="photo" id="photo" type="file" name="photo" class="form-control">
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CatalogCategoryUpdate',
        computed: {
            categories() {
                return this.$store.state.catalog.categories
            },
            category() {
                return this.$store.state.catalog.category
            }
        },
        methods: {
            ...mapActions([
                'catalogCategories',
                'catalogCategoryGet',
                'catalogCategoryUpdate',
            ]),
            async getCategory() {
                await this.catalogCategoryGet({
                    id: this.$route.params.id,
                })
            },
            async getCategories() {
                await this.catalogCategories({
                    id: null
                })
            },
            async update() {
                const formData = new FormData();
                formData.append("photo", this.$refs.photo.files[0]);
                formData.append("id", this.category.id);
                formData.append("title", this.category.title);
                formData.append("parent_id", this.category.parent_id);

                await this.catalogCategoryUpdate(formData)
            }
        },
        created() {
            this.getCategory();
            this.getCategories();
        },
    }
</script>